<template>
  <va-inner-loading :loading="loading" color="primary">
    <va-form ref="form">
      <va-input
        class="mb-3"
        v-model="data.email"
        type="email"
        :label="$t('user.form.email')"
        :rules="[useGetRules({ required: true }).rules]"
      />

      <va-input
        class="mb-3"
        v-model="data.password"
        type="password"
        :label="$t('user.form.password')"
        :rules="[useGetRules({ required: true }).rules]"
      />

      <div class="auth-layout__options d-flex align--center justify--space-between">
        <router-link style="font-weight:500" class="ml-1 link" :to="{ name: 'recover-password' }">{{
          $t('user.recover_password')
        }}</router-link>
      </div>

      <div class="d-flex justify--center mt-3">
        <va-button color="secondary" type="submit" @click="onsubmit" class="my-0">{{ $t('user.login') }}</va-button>
      </div>
    </va-form>
  </va-inner-loading>
</template>

<script>
import { LOGIN } from '@mdb/core/src';
import { useGetEntity } from '@/components/helpers/useGetEntity';
import useGetRules from '@/components/form/useGetRules';
import { ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useCookies } from 'vue3-cookies';
import AuthenticationService from '@/components/helpers/AuthenticationService';
import { useToast } from 'vue-toastification';
const toast = useToast();
import { useI18n } from 'vue-i18n';
import { useFormMutation } from '@/components/helpers/useFormMutation';

export default {
  name: 'login',
  setup() {
    const form = ref('loginForm');
    const { cookies } = useCookies();
    const queryOptions = ref({ enabled: false, notifyOnNetworkStatusChange: true });
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n({ useScope: 'global' });

    const data = ref({ email: '', password: '' });

    const { loading, error, onDone, mutate } = useFormMutation(LOGIN, false);

    onDone(({ data: { result } }) => {
      AuthenticationService.login(result);
      router.push({ path: route.query?.redirect || '/' });
    });

    const onsubmit = () => {
      if (form.value.validate()) {
        mutate({ data: data.value });
      }
    };
    return {
      data,
      onsubmit,
      useGetRules,
      form,
      loading
    };
  }
};
</script>
